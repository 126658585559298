<template>
	<div class="order-new">

		<v-progress-circular
			indeterminate
			color="purple"
			:size="100" />

	</div>
</template>

<script>
export default {
	name: 'order-new',
	mounted() {
		let orderID = 0;

		setTimeout(() => {
			this.error = null;

			this.$store.dispatch('post', {
				action: 'OrderItemController',
			}).then((res) => {
				orderID = res.page?.OrderItemController?.item?.ID;
			}).catch((error) => {
				console.log("ERROR: ", error);
			})
		}, 0);

		setTimeout(() => {
			this.$router.push({name: 'order', params: {id: orderID}});
		}, 2000);
	}
}
</script>

<style lang="scss">
.order-new {
	display: grid;
	place-items: center;
	min-height: calc(100vh - 140px);
}
</style>
